/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/neonbox6.jpg";
import bgBack from "assets/images/neonbox4.jpg";
import React from "react";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={0} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    SINAR FAEYZA
                    <br />
                    Jasa Advertising
                  </>
                }
                description="Jasa Pembuatan Neon Box, Huruf Timbul, Papan Nama, & Branding Kendaraan Yogyakarta"
              />
              <RotatingCardBack
                image={bgBack}
                title="Hubungi Kami"
                description="Telp / SMS / WA. 085325937028"
                description2="Email : sinarfaeyza@gmail.com"
                description3="Alamat : Ngelo RT 02, RW 19, Sukoharjo, Ngaglik, Sleman 55581"
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "klik untuk pemesanan",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} md={6} >
                <DefaultInfoCard
                  icon="thumb_up_alt"
                  title="Kualitas Terbaik"
                  description="Pengalaman di bisnis Reklame lebih dari 5 tahun dan ditunjang oleh SDM yang sangat kompeten dan solid."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="quickreply"
                  title="Pelayanan Cepat"
                  description="Kami selalu berupaya memberikan yang terbaik dengan pelayanan yang cepat dan tanggap sesuai deadline."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Harga Terjangkau"
                  description="Bahan baku produksi langsung disupply dari pabrikan dalam jumlah besar, biaya produksi jauh lebih murah."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="share_location"
                  title="Seluruh Indonesia"
                  description="Berbekal kepercayaan dari banyaknya klien yang menggunakan jasa kami, Sinar Faeyza siap menerima order se-Indonesia"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
