/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/bg2.png";
import React from "react";


function ContactUs() {
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ 
              backgroundImage: `url(${bgImage})`, 
              backgroundSize: "100% 100%",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Hubungi Kami
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
              Jika ada pertanyaan seputar produk dan layanan yang kami sediakan di website ini, atau anda ingin konsultasi dengan tim Sinar Faeyza, dengan senang hati kami akan melayani anda.
              </MKTypography>
              <MKBox width="100%">
                <MKBox display="flex" p={1}>
                  <MKTypography variant="button" color="text">
                    <i className="fas fa-phone" />
                  </MKTypography>
                  <MKTypography
                    component="span"
                    variant="button"
                    color="text"
                    opacity={0.8}
                    ml={2}
                    fontWeight="regular"
                  >
                    (+62) 853 2593 7028
                  </MKTypography>
                </MKBox>
                <MKBox display="flex" color="black" p={1}>
                  <MKTypography variant="button" color="text">
                    <i className="fas fa-envelope" />
                  </MKTypography>
                  <MKTypography
                    component="span"
                    variant="button"
                    color="text"
                    opacity={0.8}
                    ml={2}
                    fontWeight="regular"
                  >
                    sinarfaeyza@gmail.com
                  </MKTypography>
                </MKBox>
                <MKBox display="flex" color="text" p={1}>
                  <MKTypography variant="button" color="text">
                    <i className="fas fa-map-marker-alt" />
                  </MKTypography>
                  <MKTypography
                    component="span"
                    variant="button"
                    color="text"
                    opacity={0.8}
                    ml={2}
                    fontWeight="regular"
                  >
                    Ngelo RT 02, RW 19, Sukoharjo, Ngaglik, Sleman 55581
                  </MKTypography>
                </MKBox>
                <MKBox pt={0} pb={3} mx={3}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d247.10697078283263!2d110.42555100724346!3d-7.714069770507716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5986babd01a7%3A0x47f2598fe5f9af56!2sSinar%20Faeyza%20Neon%20Box%20dan%20Huruf%20Timbul!5e0!3m2!1sen!2sid!4v1680180876344!5m2!1sen!2sid"
                      width="250px"
                      height="100%"
                      frameBorder="0"
                      style={{ border: 0, minHeight: "200px" }}
                      allowFullScreen=""
                      aria-hidden="false"
                      tabIndex="0"
                    />
                  </MKBox>
                <Grid container item justifyContent="center" xs={12} mt={0} mb={2}>
                  <MKButton 
                    href="https://wa.me/6285325937028?text=Halo%Sinar%20Faeyza%2C%20saya%20ingin%20pesan%20jasa%20advertising.%20Terimakasih" 
                    target="_blank" 
                    color="success" 
                    variant="gradient"
                  >
                    <WhatsAppIcon/>&nbsp;
                    klik untuk pemesanan
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
