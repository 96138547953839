/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const imagesPrefix = "static/images";

export default [
  {
    title: "Neon Box",
    description: "Jasa Pembuatan Neon Box",
    items: [
      {
        image: `${imagesPrefix}/neonbox.jpg`,
        name: "neonbox1",
        route: "#",
      },
      {
        image: `${imagesPrefix}/neonbox2.jpg`,
        name: "neonbox2",
        route: "#",
      },
      {
        image: `${imagesPrefix}/neonbox3.jpg`,
        name: "neonbox3",
        route: "#",
      },
      {
        image: `${imagesPrefix}/neonbox4.jpg`,
        name: "neonbox4",
        route: "#",
      },
      {
        image: `${imagesPrefix}/neonbox5.jpg`,
        name: "neonbox5",
        route: "#",
      },
      {
        image: `${imagesPrefix}/neonbox6.jpg`,
        name: "neonbox6",
        route: "#",
      },
      {
        image: `${imagesPrefix}/neonbox7.jpg`,
        name: "neonbox7",
        route: "#",
      },
      {
        image: `${imagesPrefix}/neonbox8.jpg`,
        name: "neonbox8",
        route: "#",
      },
      {
        image: `${imagesPrefix}/neonbox9.jpg`,
        name: "neonbox9",
        route: "#",
      },
      {
        image: `${imagesPrefix}/neonbox10.jpg`,
        name: "neonbox10",
        route: "#",
      },
      {
        image: `${imagesPrefix}/neonbox11.jpg`,
        name: "neonbox11",
        route: "#",
      },
    ],
  },
  {
    title: "Huruf Timbul",
    description: "Jasa Huruf Timbul",
    items: [
      {
        image: `${imagesPrefix}/huruftimbul1.jpg`,
        name: "huruftimbul1",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul2.jpg`,
        name: "huruftimbul2",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul3.jpg`,
        name: "huruftimbul3",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul4.jpg`,
        name: "huruftimbul4",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul5.jpg`,
        name: "huruftimbul5",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul6.jpg`,
        name: "huruftimbul6",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul7.jpg`,
        name: "huruftimbul7",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul8.jpg`,
        name: "huruftimbul8",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul9.jpg`,
        name: "huruftimbul9",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul10.jpg`,
        name: "huruftimbul10",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul11.jpg`,
        name: "huruftimbul11",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul12.jpg`,
        name: "huruftimbul12",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul13.jpg`,
        name: "huruftimbul13",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul14.jpg`,
        name: "huruftimbul14",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul15.jpg`,
        name: "huruftimbul15",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul16.jpg`,
        name: "huruftimbul16",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul17.jpg`,
        name: "huruftimbul17",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul18.jpg`,
        name: "huruftimbul18",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul19.jpg`,
        name: "huruftimbul19",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul20.jpg`,
        name: "huruftimbul20",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul21.jpg`,
        name: "huruftimbul21",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul22.jpg`,
        name: "huruftimbul22",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul23.jpg`,
        name: "huruftimbul23",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul24.jpg`,
        name: "huruftimbul24",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul25.jpg`,
        name: "huruftimbul25",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul26.jpg`,
        name: "huruftimbul26",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul27.jpg`,
        name: "huruftimbul27",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul28.jpg`,
        name: "huruftimbul28",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul29.jpg`,
        name: "huruftimbul29",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul30.jpg`,
        name: "huruftimbul30",
        route: "#",
      },
      {
        image: `${imagesPrefix}/huruftimbul31.jpg`,
        name: "huruftimbul31",
        route: "#",
      },
    ],
  },
  {
    title: "Papan Nama",
    description: "Jasa Papan Nama",
    items: [
      {
        image: `${imagesPrefix}/papannama1.jpg`,
        name: "papannama1",
        route: "#",
      },
      {
        image: `${imagesPrefix}/papannama2.jpg`,
        name: "papannama2",
        route: "#",
      },
      {
        image: `${imagesPrefix}/papannama3.jpg`,
        name: "papannama3",
        route: "#",
      },
    ],
  },
  {
    title: "Branding",
    description: "Jasa Branding Kendaraan",
    items: [
      {
        image: `${imagesPrefix}/brandingbus.jpg`,
        name: "brandingbus1",
        route: "#",
      },
      {
        image: `${imagesPrefix}/brandingbus2.jpg`,
        name: "brandingbus2",
        route: "#",
      },
      {
        image: `${imagesPrefix}/brandingbus3.jpg`,
        name: "brandingbus3",
        route: "#",
      },
      {
        image: `${imagesPrefix}/brandingbus4.jpg`,
        name: "brandingbus4",
        route: "#",
      },
    ],
  },
];
