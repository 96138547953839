/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// Presentation page components
import ExampleCard from "pages/Services/components/ExampleCard";

// Data
import img1 from "assets/images/neonbox.jpg";
import img2 from "assets/images/neonbox2.jpg";
import img3 from "assets/images/neonbox3.jpg";
import img4 from "assets/images/neonbox4.jpg";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";


function Pages() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setselectedImage] = useState(img1);

  return (
    <>
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "left", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Produk & Layanan"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            Neon Box
          </MKTypography>
          <MKTypography variant="body1" color="text">
            <br /> 
            Neon Box adalah bentuk promosi iklan dengan menggunakan media flexy backlite digital printing / cutting sticker / acrylic. Neon box lebih terkesan menarik, karena pada saat malam hari media ini menyala secara keseluruhan untuk menerangi visualisasi brand yang tertera. Dimana box didalamnya terdapat lampu neon yang dirangkai untuk pencahayaan dari dalam. Frame yang digunakan dapat berupa alumunium spray cat duco, alumunium profil ataupun steinless steel. Harga pembuatan neonbox di perusahaan kami murah dan berkualitas.
            <br /> 
            <br /> 
            Neon Box atau biasa disebut juga Neon Sign sudah menjadi kata yang umum dipakai dalam dunia advertising dan periklanan di Indonesia. Berbeda dengan jenis papan reklame lainnya—seperti baliho atau spanduk—neonbox adalah jenis reklame yang akan semakin menarik perhatian masyarakat justru di saat malam hari.
            <br />
            <br />
            Sebagai penyedia Jasa Reklame, Sinar Faeyza melayani Jasa Pembuatan dan Pemasangan Neon Box Termurah untuk seluruh wilayah Indonesia.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 6, lg: 10 } }}>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h4" fontWeight="regular" mb={1}>
                Berikut beberapa  merupakan contoh dan hasil yang <b>TIM SINAR FAEYZA kerjakan</b>, untuk neon box jogja
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                Tunggu apa lagi langsung hubungi sekarang juga utuk memesan jasa pembautan neon box jogja, dan dapatkan harga istimewa seperti Kota Jogja! Untuk anda klien dari media internet :
                <br />
                <i className="fas fa-phone" />&nbsp; 0853 2593 7028  (WA/SMS)
                <br />
                <i className="fas fa-envelope" />&nbsp; sinarfaeyza@gmail.com
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={9} sx={{ mt: 1, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }}>
                <Link to="" onClick={() => {setIsOpen(true); setselectedImage(img1)}}>
                  <ExampleCard image={img1} name="neonbox" display="grid" minHeight="auto" />
                </Link>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }}>
                <Link to="" onClick={() => {setIsOpen(true); setselectedImage(img2)}}>
                  <ExampleCard image={img2} name="neonbox" display="grid" minHeight="auto" />
                </Link>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }}>
                <Link to="" onClick={() => {setIsOpen(true); setselectedImage(img3)}}>
                  <ExampleCard image={img3} name="neonbox" display="grid" minHeight="auto" />
                </Link>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }}>
                <Link to="" onClick={() => {setIsOpen(true); setselectedImage(img4)}}>
                  <ExampleCard image={img4} name="neonbox" display="grid" minHeight="auto" />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
    {isOpen && (
      <Lightbox
        mainSrc={selectedImage}
        onCloseRequest={() => setIsOpen(false)}
      />
    )}
    </>
  );
}

export default Pages;
