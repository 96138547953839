/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import jcoLogo from "assets/images/logos/gray-logos/logo-jco.png";
import jandreanLogo from "assets/images/logos/gray-logos/logo-jandrean.png";
import triLogo from "assets/images/logos/gray-logos/logo-tri.png";
import bslemanLogo from "assets/images/logos/gray-logos/logo-banksleman.png";
import saganLogo from "assets/images/logos/gray-logos/logo-sagan.png";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Dipercaya oleh lebih dari</MKTypography>
          <MKTypography variant="h1" color="info" textGradient mb={2}>
            100+ klien
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            Jika anda sedang mencari Jasa Pembuatan Reklame Termurah maka anda sudah berada di tempat yang tepat. Kami melayani klien dengan hati dan menjamin kualitas yang terbaik
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Koka Cheese Tea"
              date="3 months ago"
              review="Bagi kami yang sedang memulai usaha, membuat Neonbox disini sangatlah membantu kamu, dengan harga yang relatif murah sebanding dengan kualitasnya. apalagi free konsultasi desain mengingat kami juga tidak bisa desain."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Anto Madu"
              date="1 week ago"
              review="Murah meriah dan juga free instalasi. Masnya yang melayani juga sabar dengan permintaan saya yang cukup macam-macam dan mungkin bikin bingung. Sangat rekomended."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Teduh Mart"
              date="1 year ago"
              review="Cukup terbantu dengan konsultasi desain yang gratis, karena saya juga awam di bidang desain. Bisa saya rekomendasikan ke rekan2 yang mau buat neon box."
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={6} md={4} lg={2} mx={"auto"} sx={{ textAlign: "center" }}>
            <MKBox component="img" src={triLogo} alt="Tri" width="60%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={jcoLogo} alt="JCO" width="90%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2} mx={"auto"} sx={{ textAlign: "center" }}>
            <MKBox component="img" src={jandreanLogo} alt="JhonnyAndrean" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={bslemanLogo} alt="BankSleman" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2} mx={"auto"} sx={{ textAlign: "center" }}>
            <MKBox
              component="img"
              src={saganLogo}
              alt="SaganHeritage"
              width="80%"
              opacity={0.6}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
