/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// Presentation page components
import ExampleCard from "pages/Services/components/ExampleCard";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

// Data
import img1 from "assets/images/papannama1.jpg";
import img2 from "assets/images/papannama2.jpg";
import img3 from "assets/images/papannama3.jpg";
import img4 from "assets/images/papannama1.jpg";


function Pages() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setselectedImage] = useState(img1);

  return (
    <>
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "left", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Produk & Layanan"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            Papan Nama
          </MKTypography>
          <MKTypography variant="body1" color="text">
            <br /> 
            Jika anda ingin ngopi santai dengan teman teman anda, anda cukup mencari papan nama cafe disekitar anda. Ya, itulah fungsi dari papan nama perusahaan yaitu sebagai penanda keberadaan suatu tempat usaha agar trafik atau konsumen tempat usaha tersebut naik karena mudah untuk menemukanya lewat papan nama.
            <br /> 
            <br /> 
            Papan nama perusahaan ini biasanya dibuat dengan bahan acrylic atau backlite. Bisa juga diberikan lampu led di dalamnya untuk penerangan salama 24 jam, itu sering disebut dengan neon box. Papan nama perusahaan memang sudah menjadi kebutuhan primer para pemilik usaha, terutama dikota kota besar yang jelas banyak konsumen mengharapkan adanya penanda keberadaan suatu tempat agar mereka lebih mudah menemukan tempat yang mereka cari.
            <br />
            <br />
            Sebagai penyedia Jasa Reklame, Sinar Faeyza melayani Jasa Pembuatan dan Pemasangan Papan Nama Termurah untuk seluruh wilayah Indonesia.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 6, lg: 10 } }}>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h4" fontWeight="regular" mb={1}>
                Berikut beberapa  merupakan contoh dan hasil yang <b>TIM SINAR FAEYZA kerjakan</b>, untuk papan nama jogja
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                Tunggu apa lagi langsung hubungi sekarang juga utuk memesan jasa pembautan papan nama jogja, dan dapatkan harga istimewa seperti Kota Jogja! Untuk anda klien dari media internet :
                <br />
                <i className="fas fa-phone" />&nbsp; 0853 2593 7028  (WA/SMS)
                <br />
                <i className="fas fa-envelope" />&nbsp; sinarfaeyza@gmail.com
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={9} sx={{ mt: 1, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }}>
                <Link to="" onClick={() => {setIsOpen(true); setselectedImage(img1)}}>
                  <ExampleCard image={img1} name="papannama" display="grid" minHeight="auto" />
                </Link>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }}>
                <Link to="" onClick={() => {setIsOpen(true); setselectedImage(img2)}}>
                  <ExampleCard image={img2} name="papannama" display="grid" minHeight="auto" />
                </Link>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }}>
                <Link to="" onClick={() => {setIsOpen(true); setselectedImage(img3)}}>
                  <ExampleCard image={img3} name="papannama" display="grid" minHeight="auto" />
                </Link>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }}>
                <Link to="" onClick={() => {setIsOpen(true); setselectedImage(img4)}}>
                  <ExampleCard image={img4} name="papannama" display="grid" minHeight="auto" />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
    {isOpen && (
      <Lightbox
        mainSrc={selectedImage}
        onCloseRequest={() => setIsOpen(false)}
      />
    )}
    </>
  );
}

export default Pages;
