/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Presentation page sections
import ProdukLayanan from "pages/Home/sections/ProdukLayanan";
import Information from "pages/Home/sections/Information";
import Contact from "pages/Home/sections/Contact";
import Testimonials from "pages/Home/sections/Testimonials";

// Presentation page components

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg2.png";
import logoImg from "assets/images/logo.png";
import React from "react";


function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          minHeight: {xs: "50vh", md: "65vh"}
        }}
      >
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={7} mx={"auto"} sx={{ textAlign: "center" }}>
              <MKBox position="relative" borderRadius="lg">
              <MKBox
                  component="img"
                  bgColor="white"
                  opacity={0.6}
                  src={logoImg}
                  alt="logo-mendoel"
                  borderRadius="lg"
                  px={2}
                  shadow="sm"
                  width="100%"
                  position="absolute"
                  top={0}
                  left={0}
                  zIndex={1}
                />
                <MKBox
                  component="img"
                  src={logoImg}
                  alt="logo-mendoel"
                  borderRadius="lg"
                  px={2}
                  shadow="sm"
                  width="100%"
                  position="relative"
                  zIndex={1}
                />
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 0, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <ProdukLayanan />
        <Information />
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            justifyContent="center"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography variant="h2">SINAR FAEYZA</MKTypography>
            <MKTypography variant="body1" color="text" mt={2} mb={10}>
              Perusahaan Jasa pembuatan Media Promosi luar ruang (outdoor advertising) maupun dalam ruang (indoor advertising) dengan visualisasi yang terus menerus kami kembangkan.
            </MKTypography>
          </Grid>
        </Container>
        <Contact />
        <Testimonials />
      </Card>
      <MKBox pt={2} px={1} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
