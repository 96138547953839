// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo.png";
import React from "react";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Sinar Faeyza",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "1",
    },
    {
      icon: <TwitterIcon />,
      link: "2",
    },
    {
      icon: <WhatsAppIcon />,
      link: "https://wa.me/6285325937028?text=Halo%20Mendoel%20Jogja%2C%20saya%20ingin%20pesan%20jasa%20advertising.%20Terimakasih",
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Sinar Faeyza.
    </MKTypography>
  ),
};
