/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Snackbar from '@mui/material/Snackbar';
import Fab from '@mui/material/Fab';


// Material Kit 2 React themes
import theme from "assets/theme";


// Material Kit 2 React routes
import routes from "routes";

// Material Kit 2 React components
import MKButton from "components/MKButton";
import React from "react";



export default function App() {
  const { pathname } = useLocation();
  const [openPesanWA, setOpenPesanWA] = useState(false);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    setOpenPesanWA(true);
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
      </Routes>
      <Snackbar open={openPesanWA} anchorOrigin={{ vertical:"bottom", horizontal:"right" }}  
        sx={{
          display: {xs: "none", md: "flex"}
        }}
      >
        <MKButton 
          href="https://wa.me/6285325937028?text=Halo%Sinar%20Faeyza%2C%20saya%20ingin%20pesan%20jasa%20advertising.%20Terimakasih" 
          target="_blank" 
          variant="gradient"
          size="large"
          color="success"
          fullWidth
        >
          <WhatsAppIcon/>&nbsp;
          Hubungi Kami Melalui WhatsApp
        </MKButton>
      </Snackbar>
      <Fab
        sx={{
          position: "fixed",
          zIndex: "1",
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          fontSize: "3rem",
          backgroundColor: "#25D366",
          width: "5rem",
          height: "5rem",
          display: {xs: "flex", md: "none"}
        }}
        color="secondary"
        onClick={() => window.open("https://wa.me/6285325937028?text=Halo%Sinar%20Faeyza%2C%20saya%20ingin%20pesan%20jasa%20advertising.%20Terimakasih", "_blank")}
      >
        <WhatsAppIcon color="white"/>
      </Fab>
      
    </ThemeProvider>
  );
}
