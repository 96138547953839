/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";

// Images
import post1 from "assets/images/neonbox7.jpg";
import post2 from "assets/images/huruftimbul3.jpg";
import post3 from "assets/images/papannama3.jpg";
import post4 from "assets/images/brandingbus3.jpg";

function ProdukLayanan() {
  return (
    <MKBox component="section" py={2} mt={8}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" mb={6}>
            Produk & Layanan
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post1}
              title="Neon Box"
              description="Terlihat dengan jelas di kapanpun. Disain nya yang simple dan elegan juga dapat mempresentasikan iklan anda dengan baik..."
              action={{
                type: "internal",
                route: "/services/neon-box",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post2}
              title="Huruf Timbul"
              description="Mepresentasikan citra perusahaan anda dengan sempurna dan elegan. Terlihat menunjukan citra yang kuat pada sebuah perusahaan..."
              action={{
                type: "internal",
                route: "/services/huruf-timbul",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post3}
              title="Papan Nama"
              description="Mampu menarik perhatian banyak orang. Serta sering ditempatkan di daerah keramaian yang memiliki lalu lintas yang tinggi..."
              action={{
                type: "internal",
                route: "/services/papan-nama",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <TransparentBlogCard
              image={post4}
              title="Branding"
              description="Jenis advertising yang unik. kenapa unik ? Karena jenis advertising ini bergerak, maka produk atau usaha anda dapat dilihat oleh ribuan mata setiap harinya..."
              action={{
                type: "internal",
                route: "/services/branding",
                color: "info",
                label: "read more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ProdukLayanan;
